<template>
  <div class="payment">
    <van-nav-bar
      :title="integralOrder ? '兑换成功' : '支付成功'"
      @click-left="
        integralOrder ? $router.replace('/pointsMall') : $router.replace('/')
      "
      :fixed="true"
    >
      <template #left>
        <svg-icon icon-class="close" />
      </template>
    </van-nav-bar>
    <div class="main">
      <svg-icon icon-class="Pay_ok" class="Pay_ok" />
      <p class="success">{{ integralOrder ? "兑换成功" : "付款成功" }}</p>
      <p class="price" v-if="amount">
        支付金额<span><i>￥</i>{{ amount }}</span>
      </p>
      <p class="get_an_integral" v-if="integral">
        恭喜您此次支付获得<span>{{ integral }}积分</span>
      </p>
      <div class="btn">
        <van-button
          round
          color="#009A4D"
          plain
          @click="
            integralOrder
              ? $router.replace('/pointsMall/integralOrder')
              : $router.replace('/myOrder')
          "
          >{{ integralOrder ? "查看积分订单" : "查看订单" }}</van-button
        >
        <van-button
          round
          color="#009A4D"
          @click="
            integralOrder
              ? $router.replace('/pointsMall')
              : $router.replace('/')
          "
          >{{ integralOrder ? "回到积分商城" : "回到首页" }}</van-button
        >
      </div>
      <div class="customer_service">
        <div class="text">
          <p>添加正品汇客服好友，不定时领取社群福利</p>
          <span>长按图片识别二维码添加客服微信</span>
        </div>
        <img src="@/assets/images/customer_service.png" alt="" />
      </div>
    </div>
    <div class="bottom" v-if="!integralOrder">
      <div></div>
      <div class="box">
        <p>
          <svg-icon icon-class="xdcg_icon" style="margin-right: 2px" />下单成功
        </p>
        <p>点击晒单按钮分享微信群晒一下吧~</p>
      </div>
      <van-button round color="#009A4D" @click="toSinglePoster"
        ><svg-icon icon-class="fx_icon" />去晒单</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "completion-of-payment",

  data() {
    return {
      amount: 0.0,
      integral: null,
      integralOrder: false,
    };
  },

  created() {
    this.$route.query.id
      ? sessionStorage.setItem("userUuid", this.$route.query.id)
      : "";
    if (this.$route.query.dealNumber) {
      this.getDealDetails();
    } else if (this.$route.query.totalDealNumber) {
      this.getTotalDealDetails();
    } else if (this.$route.query.integralDealNumber) {
      this.integralOrder = true;
    }
    // dealNumber
    // totalDealNumber
  },
  beforeDestroy() {
    sessionStorage.removeItem("orderNumber");
  },
  methods: {
    async getTotalDealDetails() {
      //totalDealNumber
      const data = await this.$API.order.TotalDealDetails({
        object: {
          totalDealNumber: this.$route.query.totalDealNumber,
        },
      });
      this.amount = data.data.data.dealDetailsRsps[0].totalAmount.toFixed(2);
      this.integral = data.data.data.integral;
    },
    async getDealDetails() {
      const data = await this.$API.order.DealDetails({
        object: {
          dealNumber: this.$route.query.dealNumber,
        },
      });
      data.data.data.orderCommodityListItems.forEach((goods) => {
        this.amount += goods.actualPayment;
      });
      this.amount = this.amount.toFixed(2);
      this.integral = data.data.data.integral;
      console.log(data.data.data.integral);
    },
    toSinglePoster() {
      this.$router.replace({
        path: "/singlePoster",
        query: {
          dealNumber: this.$route.query.dealNumber
            ? this.$route.query.dealNumber
            : undefined,
          totalDealNumber: this.$route.query.totalDealNumber
            ? this.$route.query.totalDealNumber
            : undefined,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  // position: absolute;
  // top: 46px;
  // left: 0;
  // right: 0;
  // bottom: 60px;
  padding-top: 46px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background: #f7f7f7;
  overflow-y: scroll;
  .main {
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    padding: 20px 28px 0;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    text-align: center;
    .Pay_ok {
      font-size: 90px;
    }
    .success {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 25px;
      margin-bottom: 10px;
    }
    .price {
      font-size: 16px;
      color: #333333;
      span {
        margin-left: 4px;
        color: #fb4e10;
        font-size: 18px;
        font-weight: bold;
      }
      i {
        font-size: 12px;
        font-style: normal;
      }
    }
    .get_an_integral {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      span {
        color: #f98f10;
      }
    }
    .btn {
      margin: 20px 0;
      .van-button {
        height: 36px;
        margin: 0 8px;
      }
    }
    .customer_service {
      border-top: 1px dashed #f2f2f2;
      padding: 18px 0;
      display: flex;
      .text {
        text-align: start;
        p {
          font-weight: bold;
          color: #333333;
          line-height: 20px;
          margin-bottom: 4px;
        }
        span {
          font-size: 12px;
          color: #999999;
        }
      }
      img {
        margin-left: 15%;
        width: 60px;
        height: 60px;
        -webkit-touch-callout: none;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding: 0 15px 8px;
    .box {
      padding: 20px 0;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #009a4d;
        line-height: 24px;
        text-align: center;
      }
    }
    .van-button .svg-icon {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
</style>
